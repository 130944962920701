<template>
  <v-card flat class="timeline-page">
    <v-progress-linear v-if="loading"
      :color="systemDetails.themecolor"
      indeterminate
      height="6"
    ></v-progress-linear>
    <v-container fluid class="pa-0 data-iterator-size-fix">
      <v-row no-gutters class="pa-0" v-if="!$formatter.isEmptyObject(activitiesListObj)">
        <v-col cols="12" class="text-center pa-0">
          <v-data-iterator class="pa-0" :items="listOfActivitiesActualList" :options.sync="pagination" @update:page="getListOfActivities" @update:items-per-page="getListOfActivities"
          :footer-props="paginationList" :server-items-length="total ? total : undefined">
            <template #no-data>
              <span></span>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>
    <div class="module-activities">
      <!-- comments texteditor -->
      <v-container fluid :class="['pa-0', showEditor ? 'mb-4' : '']">
        <v-row no-gutters>
          <v-col cols="12" class="pa-0">
            <v-card :class="['ma-2', $vuetify.theme.dark ? '' : 'grey lighten-5', $formatter.isEmptyObject(activitiesListObj) ? 'mb-0' : 'mt-0']"
            v-show="showEditor" tile flat :loading="editorLoading" :disabled="editorLoading">
            <v-card-title class="pa-0">
              <div style="width: 90%; float: left;">
                <text-editor></text-editor>
              </div>
            </v-card-title>
              <v-card-text>
                <!-- editor content -->
                <!-- <div :contenteditable="!editorLoading" aria-placeholder="true" :class="['editor-content pa-1', $vuetify.theme.dark ? 'themedark' : '']"
                  @keypress="onKeyPressComment" @paste="onEditorPasteEvent" style="width: 100%;">
                </div> -->
                <!-- mentions users list -->
                <v-menu v-model="editorMentionsMenu" :position-x="editorMentionsMenuPos.left" :position-y="editorMentionsMenuPos.top"
                nudge-right="22" :close-on-content-click="false">
                  <v-card>
                    <v-card-title class="pa-0">
                      <v-text-field solo flat dense hide-details v-model="mentionUserSearch" ref="menuListSearch"
                      persistent-placeholder :placeholder="$t('search')" autofocus></v-text-field>
                    </v-card-title>
                    <v-card-text class="pa-0">
                      <v-list dense max-height="200" class="body-2 py-1 user-mentions-list overflow-auto"
                      v-model="selectedUser" @change="executeJSCommand({ command: 'insertUser', value: user })">
                        <v-list-item v-for="(user, index) in mentionUserList" :key="index"
                        :tabindex="(index + 1)" @click.stop="executeJSCommand({ command: 'insertUser', value: user })">
                          {{ user.name }}
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <!-- action buttons -->
                <div class="d-flex editor-content-actions">
                  <v-btn fab x-small elevation="0" color="success" class="mx-2" @click="addNewComment" height="25" width="25"
                  :loading="editorLoading">
                    <v-icon size="12"> mdi-send </v-icon>
                  </v-btn>
                  <div>
                    <v-badge :content="commentFiles ? commentFiles.length : ''" offset-x="20" offset-y="10"
                    :value="commentFiles && commentFiles.length">
                      <v-btn fab x-small elevation="0" color="primary" class="mr-1" height="25" width="25"
                      @click="$refs.file.$refs.input.click && $refs.file.$refs.input.click()"
                      >
                        <v-icon size="12"> mdi-file </v-icon>
                      </v-btn>
                    </v-badge>
                    <v-file-input ref="file" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, image/png, image/jpeg, image/jpg, image/gif" v-model="commentFiles"
                      multiple class="d-none" id="fileSelection" @change="onFilesChange;restrictFileSelection()" ></v-file-input>
                  </div>
                  <v-btn class="ml-1" v-if="showEditor" fab x-small elevation="0" @click="showHideEditor();" color="error"  height="25" width="25">
                    <v-icon size="14">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-actions class="pa-0" :class="commentFiles.length ? 'pb-3':''">
                <v-col class="pa-0" cols="12">
                  <v-chip v-for="(file, index) in commentFiles" :key="index" class="ma-1 mt-0" :close="!file.sharepointid" small label
                     @click:close="commentFiles.splice(index, 1)" style="max-width: 21vw;" :title="file.name || file.originalfilename">
                    <v-icon x-small left> mdi-file </v-icon>
                    <span :class="['text-truncate font-weight-medium', !!file.is_deleted ? 'text-decoration-line-through' : '']">
                    {{ file.name || file.originalfilename }}
                    </span>
                  </v-chip>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-timeline class="pt-0 mt-5" v-if="!$formatter.isEmptyObject(activitiesListObj)">
        <template v-for="(item, i) in activitiesListObj">
          <v-timeline-item  class="apply-max-content" fill-dot right :color="$vuetify.theme.dark ? 'black' : 'white'" :key="i" :class="['mb-2 font-weight-medium body-2', $vuetify.theme.dark ? 'dark-theme-activity-date-timeline' : 'activity-date-timeline']">
            <template #icon>
              <!-- <span  class="caption font-weight-medium d-inline-block text-truncate"> {{ i }} </span> -->
              <span> {{ i }} </span>
            </template>
          </v-timeline-item>
          <v-timeline-item small color="teal" v-for="(activity, index) in item" :key="`subitem_${i}_${index}`" right>
            <!-- avatar -->
            <template #icon>
              <v-avatar size="30" v-if="activity.iscomment && includeComments" class="elevation-4">
                <img :src="`${$_imageUrl}${activity.createdby.profile}`" :alt="activity.createdby.name" v-if="activity.createdby && activity.createdby.profile">
                <img src="@/assets/profile.jpg" :alt="activity.createdby.name" v-else>
              </v-avatar>
              <v-btn fab height="25" width="25" color="primary" elevation="4" v-else>
                <v-icon size="12"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <!-- content -->
            <!-- comment -->
            <template v-if="(!!activity.iscomment) && includeComments">
              <div>
                <span class="caption font-weight-medium"> {{ activity.createdby.name }} </span>
                <!-- <span class="px-1" style="font-size: 0.7rem;"> {{ $formatter.fromUtcToLocal(activity.modified_at, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') }} </span> -->
                <span class="px-1" style="font-size: 0.7rem;"> {{ $formatter.formatDate(activity.modified_at, '', 'HH:mm') }} </span>
              </div>
              <v-hover v-slot="{ hover }" close-delay="100">
                <div>
                  <label class="text-size" v-html="activity.message"></label>
                  <v-btn x-small outlined class="px-2 my-1" v-if="(hover || $vuetify.breakpoint.xsOnly) && (activity.createdby._id === userDetails._id)"
                  @click="editActivity(activity)">
                    <v-icon x-small class="pr-1"> mdi-pencil </v-icon>
                    <span class="text-capitalize"> {{ $t('edit') }} </span>
                  </v-btn>
                </div>
              </v-hover>
              <div v-if="activity.attachments && activity.attachments.length">
                <v-chip v-for="(file, index) in activity.attachments" :key="index" class="mb-1 mr-1" small
                label style="max-width: 25vw;">
                  <template #default>
                    <v-icon left size="16"> mdi-file </v-icon>
                    <span :class="['text-truncate font-weight-medium', file.is_deleted ? 'text-decoration-line-through' : '']"
                    :title="file.originalfilename">
                      {{ file.originalfilename }}
                    </span>
                    <v-icon right small class="cursor-pointer" @click="openActivityFile(file)"
                    v-if="!file.is_deleted" :title="$t('preview')"> mdi-open-in-new </v-icon>
                    <v-icon right small class="cursor-pointer" @click="deleteActivityFile(activity, file)"
                    v-if="!file.is_deleted && (activity.createdby._id === userDetails._id)" :title="$t('delete')"> mdi-trash-can </v-icon>
                  </template>
                </v-chip>
              </div>
            </template>
            <!-- history -->
            <template v-else>
              <template v-if="activity.issharepointaction">
                {{ activity.message }} {{ activity.isfolder ? $t('folder') : $t('file') }} {{ $t(activity.action) }} {{ $t('by') }}
                 <strong>
                  {{
                    activity[activity.action === 'created' ? 'createdby' : 'modifiedby']._id === userDetails._id
                      ? $t('you')
                      : activity[activity.action === 'created' ? 'createdby' : 'modifiedby'].name
                  }}
                 </strong>
                 <span class="d-flex caption font-weight-medium">
                    <v-icon small class="mr-1"> mdi-clock-time-eight </v-icon>
                    {{ $formatter.formatDate(activity.created_at, '', 'HH:mm') }}
                  </span>
              </template>
              <template v-else-if="activity.isrestoredrecord || activity.isdeletedrecord">
                {{ $t('record') }} {{ activity.isdeletedrecord ? $t('deleted') : $t('restored') }} {{ $t('by') }}
                 <span class="body-2 font-weight-medium text-capitalize">
                    {{ (activity.createdby._id === userDetails._id) ? $t('you') : activity.createdby.name }}
                  </span>
                  <span class="d-flex caption font-weight-medium">
                    <v-icon small class="mr-1"> mdi-clock-time-eight </v-icon>
                    {{ $formatter.formatDate(activity.created_at, '', 'HH:mm') }}
                  </span>
              </template>
              <template v-else>
                <template v-if="activity.iscreatedrecord || activity.isimportedrecord">
                  <span class="body-2">
                    {{ activity.isimportedrecord ? $t('recordImportedBy') : $t('recordCreatedBy') }}
                  </span>
                  <span class="body-2 font-weight-medium text-capitalize">
                    {{ (activity.createdby._id === userDetails._id) ? $t('you') : activity.createdby.name }}
                  </span>
                  <span class="d-flex caption font-weight-medium">
                    <v-icon small class="mr-1"> mdi-clock-time-eight </v-icon>
                    {{ $formatter.formatDate(activity.created_at, '', 'HH:mm') }}
                  </span>
                </template>
                <template v-else>
                  <template v-for="(fields, i) in activity.editedfields">
                    <div :key="i">
                      <span class="body-2 font-weight-medium pr-1">
                        {{ $t(fields.label) }}
                      </span>
                      <span class="body-2 pr-1">
                        {{ $t('updatedFrom') }}
                      </span>
                      <span :class="['font-weight-bold body-2 pr-1 primary--text', $vuetify.theme.dark ? 'text--lighten-1' : 'text--darken-2']">
                        <!-- Checkbox -->
                        <template v-if="fields.type === 3">
                          {{ !!fields.editedfrom ? $t('yes') : $t('no') }}
                        </template>
                        <!-- Select -->
                        <template v-else-if="fields.type === 5">
                          <!-- multi-select -->
                          <template v-if="(JSON.parse(fields.default_value)) && Boolean((JSON.parse(fields.default_value)).is_multiselect)">
                            {{ (fields.editedfrom && fields.editedfrom.length)
                              ? fields.editedfrom.map((user) => (user.data && user.data.name ? user.data.name : user)).join(', ')
                              : '""'
                            }}
                          </template>
                          <!-- single-select -->
                          <template v-else>
                            {{ (fields.editedfrom && fields.editedfrom.length)
                              ? (fields.editedfrom[0] && fields.editedfrom[0].data && fields.editedfrom[0].data.name) ? fields.editedfrom[0].data.name : fields.editedfrom[0]
                              : '""'
                            }}
                          </template>
                        </template>
                        <!-- Date or DateTime -->
                        <template v-else-if="(fields.type === 6 || fields.type === 16)">
                          {{ fields.editedfrom
                            ? $formatter.formatDate(fields.editedfrom, '', (fields.type === 6 ? userDetails.dateformat: `${userDetails.dateformat} HH:mm:ss`))
                            : '""'
                          }}
                        </template>
                        <!-- Signature -->
                        <template v-else-if="fields.type === 10">
                          <v-img :lazy-src="fields.editedfrom" :src="fields.editedfrom" height="80" width="100" contain v-if="fields.editedfrom"
                            :class="$vuetify.theme.dark ? 'signature--dark' : ''"></v-img>
                          <span v-else> "" </span>
                        </template>
                        <!-- User -->
                        <template v-else-if="fields.type === 15">
                          {{ (fields.editedfrom && fields.editedfrom.length)
                            ? fields.editedfrom.map((user) => (user.firstname ? (user.firstname + ' ' + (user.lastname || '')) : user)).join(', ')
                            : '""'
                          }}
                        </template>
                        <!-- HTML field -->
                        <template v-else-if="fields.type === 19">
                          <span v-html="fields.editedfrom"></span>
                        </template>
                        <template v-else>
                          {{ fields.editedfrom || '""' }}
                        </template>
                      </span>
                      <span class="body-2 pr-1">
                        {{ $t('smallTo') }}
                      </span>
                      <span :class="['font-weight-bold body-2 pr-1 success--text', $vuetify.theme.dark ? '' : 'text--darken-2']">
                        <!-- Checkbox -->
                        <template v-if="fields.type === 3">
                          {{ !!fields.editedto ? $t('yes') : $t('no') }}
                        </template>
                        <!-- Select -->
                        <template v-else-if="fields.type === 5 && fields.default_value">
                          <!-- multi-select -->
                          <template v-if="(JSON.parse(fields.default_value)) && Boolean((JSON.parse(fields.default_value)).is_multiselect)">
                            {{ (fields.editedto && fields.editedto.length)
                              ? fields.editedto.map((user) => (user.data && user.data.name ? user.data.name : user)).join(', ')
                              : '""'
                            }}
                          </template>
                          <!-- single-select -->
                          <template v-else>
                            {{ (fields.editedto && fields.editedto.length)
                              ? (fields.editedto[0] && fields.editedto[0].data && fields.editedto[0].data.name) ? fields.editedto[0].data.name : fields.editedto[0]
                              : '""'
                            }}
                          </template>
                        </template>
                        <!-- Date or DateTime -->
                        <template v-else-if="(fields.type === 6 || fields.type === 16)">
                          {{ fields.editedto
                            ? $formatter.formatDate(fields.editedto, '', (fields.type === 6 ? userDetails.dateformat : `${userDetails.dateformat} HH:mm:ss`))
                            : '""'
                          }}
                        </template>
                        <!-- Signature -->
                        <template v-else-if="fields.type === 10">
                          <v-img :lazy-src="fields.editedto" :src="fields.editedto" height="80" width="100" contain v-if="fields.editedto"
                            :class="$vuetify.theme.dark ? 'signature--dark' : ''"></v-img>
                          <span v-else> "" </span>
                        </template>
                        <!-- User -->
                        <template v-else-if="fields.type === 15">
                          {{ (fields.editedto && fields.editedto.length)
                            ? fields.editedto.map((user) => (user.firstname ? (user.firstname + ' ' + (user.lastname || '')) : user)).join(', ')
                            : '""'
                          }}
                        </template>
                        <!-- HTML field -->
                        <template v-else-if="fields.type === 19">
                          <span v-html="fields.editedto"></span>
                        </template>
                        <template v-else>
                          {{ fields.editedto || '""' }}
                        </template>
                      </span>
                      <br>
                      <span class="body-2 text-lowercase">
                        {{ $t('by') }}
                      </span>
                      <span class="body-2 font-weight-medium pr-1 text-capitalize">
                        {{ (activity.createdby._id === userDetails._id) ? $t('you') : activity.createdby.name }}
                      </span>
                      <span class="d-flex caption font-weight-medium">
                        <v-icon small class="mr-1"> mdi-clock-time-eight </v-icon>
                        {{ $formatter.formatDate(activity.created_at, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') }}
                      </span>
                    </div>
                  </template>
                </template>
              </template>
            </template>
          </v-timeline-item>
        </template>
      </v-timeline>
      <v-alert text prominent icon="mdi-alert" color="primary" v-if="!loading && $formatter.isEmptyObject(activitiesListObj) && !showEditor" :class="['ma-3']">
        <v-layout row wrap align-center class="ma-0">
          <label> {{ $t('noActivities') }} </label>
          <v-spacer></v-spacer>
          <v-btn text icon :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" outlined @click="showHideEditor"
            v-if="includeComments">
            <!-- {{ showEditor ? $t('hideEditor') : $t('addNewComment') }} -->
            <v-icon  class="mt-1"> mdi-message-plus </v-icon>
          </v-btn>
        </v-layout>
      </v-alert>
    </div>
    <v-speed-dial bottom right fixed class="custom-speed-dial" v-if="!$formatter.isEmptyObject(activitiesListObj) && includeComments">
      <template #activator>
        <v-btn :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" dark fab small
        @click="showHideEditor" :outlined="$vuetify.theme.dark" class="mb-1 mr-2">
          <v-icon size="20" class="mt-1"> mdi-comment-plus </v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import texteditor from '@/mixins/texteditor'
export default {
  props: ['moduleObj', 'currentModule'],
  mixins: [texteditor],
  data () {
    return {
      activitiesListObj: {},
      listOfActivitiesActualList: [],
      total: 0,
      pagination: {
        page: 1,
        itemsPerPage: 25
      },
      loading: false,
      // comments
      includeComments: true,
      commentsArray: [],
      uniqueClassIdentifier: 'comments'
    }
  },
  mounted () {
    this.includeComments = (this.currentModule && this.currentModule.include_comments)
    this.getListOfActivities()

    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'timeline-comments') {
        this.$store.commit('hideDialog')
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
        this.getListOfActivities()
      }
    })
  },
  components: {
    'text-editor': () => import('@/components/TextEditor.vue')
  },
  computed: {
    ...mapGetters(['getUsers', 'systemDetails', 'userDetails']),
    paginationList () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, 100, 250],
        itemsPerPageText: this.$t('recordsPerPage')
      }
    }
  },
  methods: {
    getListOfActivities () {
      const model = {
        moduleid: this.$route.params.id,
        recordid: this.moduleObj._id,
        page: this.pagination ? this.pagination.page : 1,
        rowsperpage: this.pagination ? this.pagination.itemsPerPage : 25
      }
      this.loading = true
      this.$api.execute('post', 'timelines/paginate_filter', model)
        .then(({ data }) => {
          if (data.records) {
            data.records.map((record) => {
              record.createdby = this.getUsers.find((user) => user._id === record.createdby)
            })
          }
          this.activitiesListObj = this.groupByDate(data.records)
          this.listOfActivitiesActualList = data.records
          this.total = data.total
        }).finally(() => {
          this.loading = false
        })
    },
    groupByDate (data) {
      var groups = {}
      data.forEach((val) => {
        var date = val.created_at.split('T')[0]
        date = this.$formatter.formatDate(date, 'DD.MM.YYYY', this.userDetails.dateformat)
        if (date in groups) groups[date].push(val)
        else groups[date] = new Array(val)
      })
      return groups
    },
    // comments
    showHideEditor () {
      const editorElem = document.querySelector('.editor-content')
      editorElem.innerHTML = ''
      this.editActivityObj = null
      this.showEditor = !this.showEditor
      this.commentFiles = []
      localStorage.removeItem('commentFiles')
      this.commentsArray = []
      window.scrollTo(0, 0)
    },
    onFilesChange () {
      if (this.editActivityObj && this.editActivityObj.attachments) {
        this.commentFiles = [...this.editActivityObj.attachments, ...this.commentFiles]
      }
    },
    restrictFileSelection () {
      let checkLength = []
      if (localStorage.getItem('commentFiles')) {
        this.commentsArray = JSON.parse(localStorage.getItem('commentFiles'))
        checkLength = [...this.commentsArray, ...this.commentFiles]
      } else {
        checkLength = this.commentFiles
      }
      if (checkLength.length >= 11) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('attachmentCountRestrictionMsg') })
        this.commentFiles = []
        checkLength = []
        if (this.commentsArray.length) this.commentFiles = this.commentsArray
      }
    },
    addNewComment () {
      const editorElem = document.querySelector('.editor-content')
      const messageLength = editorElem.innerText
      // adding { target } attribute for anchor elements
      const anchorElements = document.querySelectorAll('.editor-content a')
      if (anchorElements && anchorElements.length) {
        // for (let anchorElemIndex of anchorElements.length) {
        //   console.log(anchorElemIndex)
        //   anchorElements[anchorElemIndex].target = '_blank'
        // }
        for (let i = 0; i < anchorElements.length; i++) {
          anchorElements[i].target = '_blank'
        }
      }
      const formdata = new FormData()
      // eslint-disable-next-line
      let editorValue = editorElem.innerHTML.toString().replace(/\&nbsp;/g, '')
      // eslint-disable-next-line
      editorValue = editorValue ? editorValue.replace(/\<br>/g, '') : editorValue
      formdata.append('message', editorElem.innerHTML.toString().replace('@<span id="data-mention-container', '<span id="data-mention-container')) // removing '@' before user-mentions
      for (const file of this.commentFiles.filter((file) => !file.sharepointid)) {
        formdata.append(file.name, file)
      }
      formdata.append('moduleid', this.$route.params.id)
      formdata.append('recordid', this.moduleObj._id)
      formdata.append('iscomment', true)
      // this.editorLoading = true
      // const messageLength = formdata.get('message')
      const method = (this.editActivityObj && this.editActivityObj._id) ? 'put' : 'post'
      const url = (this.editActivityObj && this.editActivityObj._id) ? `timelines/${this.editActivityObj._id}` : 'timelines'
      if (messageLength.replace(/\s+/g, '').length || this.commentFiles.length || messageLength.replace(/<img.*?>/g, '')) {
        this.editorLoading = true
        this.$api.execute(method, url, formdata).then(() => {
          this.getListOfActivities()
        }).finally(() => {
          editorElem.innerHTML = ''
          this.commentFiles = []
          this.editorLoading = false
          this.editActivityObj = null
          localStorage.removeItem('commentFiles')
          this.commentsArray = []
        })
      }
    },
    editActivity (activity) {
      this.editActivityObj = activity
      this.commentFiles = activity.attachments
      const editorElem = document.querySelector('.editor-content')
      editorElem.innerHTML = activity.message.replace('<span data-user-id', '@<span data-user-id')
      this.showEditor = true
      if (activity.attachments.length) localStorage.setItem('commentFiles', JSON.stringify(activity.attachments))
      window.scrollTo(0, 0)
    },
    deleteActivityFile ({ _id }, { sharepointid }) {
      const payload = { url: `timelines/remove_attachment/${_id}/${sharepointid}`, module: 'timeline-comments' }
      this.$store.commit('showDeleteDialog', payload)
    },
    openActivityFile ({ sharepointurl }) {
      window.open(sharepointurl, '_blank')
    }
  }
}
</script>
<style>
  .activity-date-timeline .v-timeline-item__inner-dot {
    border-radius: 0% !important;
    border: 1px solid black !important;
  }
  .activity-date-timeline .v-timeline-item__dot {
    width: 85px !important;
    background: #f5f5f5 !important;
    border-radius: 0% !important;
  }
  .dark-theme-activity-date-timeline .v-timeline-item__inner-dot {
    width: 95px !important;
    margin-left: -27px !important;
    border-radius: 0% !important;
    border: 1px solid white !important;
  }
  .timeline-page .v-timeline {
    margin-left: -70vw !important;
  }

  /* comments texteditor */
  .timeline-page .editor-content {
    min-height: 70px;
    height: 100%;
    border: 1px solid black;
    padding: 2px;
    outline: none;
    color: black;
    position: relative;
  }
  .timeline-page .editor-content-actions {
    position: absolute;
    right: 7px;
    bottom: -27px;
    /* z-index: 1; */
  }
  .timeline-page .editor-content span.user-mention {
    color: orangered;
    /* color: #a61f49; */
  }
  .timeline-page .editor-content.themedark {
    border: 1px solid white;
    color: white;
    caret-color: white;
  }
  .timeline-page .editor-content img {
    height: 14vh;
    width: 12vw;
  }
  .timeline-page .editor-message-container {
    font-size: 0.93rem !important;
    display: flex !important;
    max-width: 66vw !important;
  }
  .timeline-page .editor-message-container + span.caption {
    border: 1px solid grey;
    padding: 2px 4px 2px 0;
    text-transform: lowercase;
    font-weight: 500;
  }
  .timeline-page .editor-message-container img {
    width: 16vw;
    height: 18vh;
    margin: 4px 0 -2px 0;
  }
  .timeline-page .editor-message-container span.user-mention {
    color: orangered;
  }
  .timeline-page .cursor-pointer {
    cursor: pointer;
  }
  /* .unselectable {
    -khtml-user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  } */
.text-size {
  font-size:0.800rem !important;
}
.data-iterator-size-fix .v-data-footer__select .v-select {
    margin-left: auto !important;
    margin-right: 0px !important;
    margin: 13px 0 13px 24px !important
}
.data-iterator-size-fix .v-data-footer__pagination {
      margin: 0 5px 0 5px !important
 }
.data-iterator-size-fix .v-data-footer__icons-before .v-btn:last-child {
    margin-right: 0px  !important
}
.data-iterator-size-fix .v-data-footer__icons-after .v-btn:first-child {
    margin-left: 0px !important
}
.data-iterator-size-fix .v-data-footer {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: center;
    font-size: 0.75rem;
    /* padding: 0 8px; */
}
.apply-max-content .v-timeline-item__divider .v-timeline-item__dot .v-timeline-item__inner-dot {
    width: max-content !important;
}
</style>
